<template>
  <div class="flex flex-col gap-y-2">

    <div id="sales-report-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="order-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">
              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_from"
                labelAddon="Od"
                :markDirty="true"
                :error="saveErrors.date_from"
              />

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_to"
                labelAddon="Do"
                :markDirty="true"
                :error="saveErrors.date_to"
              />

              <div class="hidden sm:flex w-full md:min-w-max flex-1 items-center">
                <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-left" size="lg" />
                </button>
                <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
                <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-right" size="lg" />
                </button>
              </div>

            </div>

            <div class="flex flex-wrap items-center gap-3">
              <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-0 sm:flex-1" v-model="activeFilters.insurer_id" label="TU" :options="insurerList" />

              <BaseFilterSelectWithAddon class="w-full md:min-w-max flex-0 sm:flex-1" v-model="activeFilters.employee_id" label="Agent" :options="employeeSimpleList" />
            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex-grow sm:hidden flex items-center">
            <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </button>
            <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
            <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-right" size="lg" />
            </button>
          </div>
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="init" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <button @click="getReport" class="tw--btn">Generuj raport</button>
      </div>
    </div>
    <div id="sales-report-content" class="p-3 pt-1 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="flex flex-col">
        <span class="font-semibold">Raport sprzedaży (przypis) wg daty wystawienia polis</span>
        <span v-if="activeFilters.employee_id !== ''" class="text-xs italic"><em>* nazwy TU w <span class="text-blue-500 font-size-bold">kolorze</span> oznaczają, że agent posiada przypisane uprawnienia KNF</em></span>
      </div>

      <hr class="dark:border-gray-600 border-gray-400 -mx-3">

      <div class="flex flex-wrap gap-x-5 gap-y-1 mt-2">

        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">Przypis razem</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ report01Sums.value | moneyPL }}</span>
        </div>

        <div v-if="currentUserType !== 'owfca'" class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">prowizja agenta</span>
          <span class="px-3 py-1 rounded-r bg-indigo-500 dark:bg-indigo-900">{{ report01Sums.prov_a | moneyPL }}</span>
        </div>

        <div v-if="showOfficeProvision" class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">prowizja agencji</span>
          <span class="px-3 py-1 rounded-r bg-purple-500 dark:bg-purple-900">{{ report01Sums.prov_b | moneyPL }}</span>
        </div>

      </div>

      <div class="flex flex-nowrap my-3 font-semibold">
        <a href="#"
          @click.prevent="showReportCard = 1"
          class="px-3 py-1 dark:border-gray-600 border-gray-400 border-b-0 rounded-t"
          :class="showReportCard === 1 ? 'border border-b-0 shadow-inner font-bold' : 'border-b tw--link'"
        >
          <span class="hidden sm:block">Tow. Ubezp.</span>
          <span class="block sm:hidden">TU</span>
        </a>
        <a href="#"
          @click.prevent="showReportCard = 2"
          class="px-3 py-1 dark:border-gray-600 border-gray-400 border-b-0 rounded-t"
          :class="showReportCard === 2 ? 'border border-b-0 shadow-inner font-bold' : 'border-b tw--link'"
        >Kategoria</a>
        <a href="#"
          @click.prevent="showReportCard = 3"
          class="px-3 py-1 dark:border-gray-600 border-gray-400 border-b-0 rounded-t"
          :class="showReportCard === 3 ? 'border border-b-0 shadow-inner font-bold' : 'border-b tw--link'"
        >Rodzaj</a>
        <div
          class="flex-grow px-3 py-1 dark:border-gray-600 border-gray-400 border-b"
        >&nbsp;</div>
      </div>

      <div v-show="showReportCard === 1" class="flex flex-wrap">
        <div class="w-full xl:w-1/2 pr-5">
          <div class="hidden sm:flex border-b dark:border-gray-600 font-semibold">
            <div class="flex-grow">towarzystwo ubezp. <span class="text-muted">[%przypisu]</span></div>
            <div class="w-1/5 text-right">składka</div>
            <div v-if="currentUserType !== 'owfca'" class="w-1/5 text-right">prow. agenta</div>
            <div v-if="showOfficeProvision" class="w-1/5 text-right">prow. agencji</div>
          </div>
          <div v-for="(row, index) in report01" :key="index" class="flex flex-col sm:flex-row border-b dark:border-gray-600 border-dashed border-opacity-25">
            <div class="flex-grow" :class="{'text-blue-500': employeeInsurerIds.indexOf(row.insurer_id) !== -1}">
              {{ row.insurer_name }} <span class="text-muted">[{{ report01Sums.value ? (row.subsum_skladka * 100 / report01Sums.value).toFixed(2) : 0 }}%]</span></div>
            <div class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">składka</div><div>{{ row.subsum_skladka | moneyPL }}</div>
            </div>
            <div v-if="currentUserType !== 'owfca'" class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">prow. agenta</div><div>{{ row.prowizja_agent | moneyPL }}</div>
            </div>
            <div v-if="showOfficeProvision" class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">prow. agencji</div><div>{{ row.prowizja_biuro | moneyPL }}</div>
            </div>
          </div>
        </div>
        <div class="w-full xl:w-1/2"><canvas id="report-chart1"></canvas></div>
      </div>

      <div v-show="showReportCard === 2" class="flex flex-wrap">
        <div class="w-full xl:w-1/2 pr-5">
          <div class="hidden sm:flex border-b dark:border-gray-600 font-semibold">
            <div class="flex-grow">kategoria <span class="text-muted">[%przypisu]</span></div>
            <div class="w-1/5 text-right">składka</div>
            <div v-if="currentUserType !== 'owfca'" class="w-1/5 text-right">prow. agenta</div>
            <div v-if="showOfficeProvision" class="w-1/5 text-right">prow. agencji</div>
          </div>
          <div v-for="(row, index) in report02" :key="index" class="flex flex-col sm:flex-row border-b dark:border-gray-600 border-dashed border-opacity-25">
            <div class="flex-grow" :class="{'text-blue-500': employeeInsurerIds.indexOf(row.insurer_id) !== -1}">
              {{ row.category_name }} <span class="text-muted">[{{ report01Sums.value ? (row.subsum_skladka * 100 / report01Sums.value).toFixed(2) : 0 }}%]</span></div>
            <div class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">składka</div><div>{{ row.subsum_skladka | moneyPL }}</div>
            </div>
            <div v-if="currentUserType !== 'owfca'" class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">prow. agenta</div><div>{{ row.prowizja_agent | moneyPL }}</div>
            </div>
            <div v-if="showOfficeProvision" class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">prow. agencji</div><div>{{ row.prowizja_biuro | moneyPL }}</div>
            </div>
          </div>
        </div>
        <div class="w-full xl:w-1/2"><canvas id="report-chart2"></canvas></div>
      </div>

      <div v-show="showReportCard === 3" class="flex flex-wrap">
        <div class="w-full xl:w-1/2 pr-5">
          <div class="hidden sm:flex border-b dark:border-gray-600 font-semibold">
            <div class="flex-grow">rodzaj ub. <span class="text-muted">[%przypisu]</span></div>
            <div class="w-1/5 text-right">składka</div>
            <div v-if="currentUserType !== 'owfca'" class="w-1/5 text-right">prow. agenta</div>
            <div v-if="showOfficeProvision" class="w-1/5 text-right">prow. agencji</div>
          </div>
          <div v-for="(row, index) in report03" :key="index" class="flex flex-col sm:flex-row border-b dark:border-gray-600 border-dashed border-opacity-25">
            <div class="flex-grow" :class="{'text-blue-500': employeeInsurerIds.indexOf(row.insurer_id) !== -1}">
              {{ row.type_name }} <span class="text-muted">[{{ report01Sums.value ? (row.subsum_skladka * 100 / report01Sums.value).toFixed(2) : 0 }}%]</span></div>
            <div class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">składka</div><div>{{ row.subsum_skladka | moneyPL }}</div>
            </div>
            <div v-if="currentUserType !== 'owfca'" class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">prow. agenta</div><div>{{ row.prowizja_agent | moneyPL }}</div>
            </div>
            <div v-if="showOfficeProvision" class="w-full sm:w-1/5 flex justify-between sm:justify-end">
              <div class="block sm:hidden text-muted">prow. agencji</div><div>{{ row.prowizja_biuro | moneyPL }}</div>
            </div>
          </div>
        </div>
        <div class="w-full xl:w-1/2"><canvas id="report-chart3"></canvas></div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import Chart from 'chart.js';
import moment from 'moment';
import ReportsService from '../../services/ReportsService';
import EmployeeService from '../../services/EmployeeService';

export default {
  name: 'SalesReport',
  mixins: [errorHandler],
  data() {
    return {
      showReportCard: 1,
      report01: [],
      report02: [],
      report03: [],
      report01Sums: {
        value: 0,
        prov_a: 0,
        prov_b: 0,
      },
      pieChartColors: ['rgba(18,152,208, 0.6)', 'rgba(66,161,80, 0.6)', 'rgba(251,207,68, 0.6)', 'rgba(100,59,161, 0.6)', 'rgba(248,64,50, 0.6)'],
      activeFilters: {
        insurer_id: '',
        employee_id: '',
        date_from: '',
        date_to: '',
      },
      employeeInsurerIds: [],
      saveErrors: {},
      chartData: [],
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
    ]),
    ...mapGetters('products', [
      'insurerList',
    ]),
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
    showOfficeProvision() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
    this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
    this.init();
  },
  methods: {
    init() {
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      this.getReport();
    },
    getReport() {
      this.$store.dispatch('setLoadingData', true);
      this.saveErrors = {};
      ReportsService.getSalesReport(this.activeFilters)
       .then((response) => {
          if (this.activeFilters.employee_id !== '') {
            EmployeeService.getEmployeeInsurers(this.activeFilters.employee_id)
              .then(response => {
                this.employeeInsurerIds = response.data.result.map(el => el.id);
              })
              .catch(error => this.resolveError(error));
          } else {
            this.employeeInsurerIds = [];
          }
          this.report01Sums = { value: 0, prov_a: 0, prov_b: 0 };
          this.setUrl();
          this.report01 = response.data.report01;
          this.report02 = response.data.report02;
          this.report03 = response.data.report03;
          this.chartData = [];
          this.chartData.push({ type: 'horizontalBar', data: { labels: [], datasets: [{ label: 'Składka', data: [], backgroundColor: [] }] } });
          this.report01.forEach((obj) => {
            this.chartData[0].data.labels.push(obj.insurer_name);
            this.chartData[0].data.datasets[0].data.push(obj.subsum_skladka);
            this.chartData[0].data.datasets[0].backgroundColor.push(this.pieChartColors[0]);
            this.report01Sums.value += Number(obj.subsum_skladka);
            this.report01Sums.prov_a += Number(obj.prowizja_agent);
            this.report01Sums.prov_b += Number(obj.prowizja_biuro);
          });
          this.chartData.push({ type: 'pie', data: { labels: [], datasets: [{ label: 'Składka', data: [], backgroundColor: [] }] } });
          this.report02.forEach((obj, index) => {
            this.chartData[1].data.labels.push(obj.category_name);
            this.chartData[1].data.datasets[0].data.push(obj.subsum_skladka);
            this.chartData[1].data.datasets[0].backgroundColor.push(this.pieChartColors[index]);
          });
          this.chartData.push({ type: 'horizontalBar', data: { labels: [], datasets: [{ label: 'Składka', data: [], backgroundColor: [] }] } });
          this.report03.forEach((obj) => {
            this.chartData[2].data.labels.push(obj.type_name);
            this.chartData[2].data.datasets[0].data.push(obj.subsum_skladka);
            this.chartData[2].data.datasets[0].backgroundColor.push(this.pieChartColors[0]);
          });
          this.$nextTick(() => this.createChart('report-chart1', this.chartData[0]));
          this.$nextTick(() => this.createChart('report-chart2', this.chartData[1]));
          this.$nextTick(() => this.createChart('report-chart3', this.chartData[2]));
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    prevMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_from).endOf('month').format('YYYY-MM-DD');
    },
    setToday() {
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
    },
    nextMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).add(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_from).endOf('month').format('YYYY-MM-DD');
    },
    setUrl() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.activeFilters)) {
        this.$router.replace({ name: 'SalesReport', query: this.activeFilters });
      }
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
      this.getReport();
    },
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId);
      // eslint-disable-next-line no-unused-vars
      const myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      });
    },
  },
};
</script>
